import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import settings from "../settings"
import "./city-page.scss"

export default function CityPage({ data }) {
  if (!(data && data.mysqlCities)) {
    return <Layout>Not found</Layout>
  }
  const city = data.mysqlCities
  return (
    <Layout>
      <Container className="statepage">

          <h2 className="align-center">Explore {city.title}</h2>
          <Row className="overviewContent">
            <div
              className="align-center"
              dangerouslySetInnerHTML={{
                __html: city.overview,
              }}
            ></div>
          </Row>

          <Row className="card-container">
            {city.itineraries.map(itinerary => {
              const content = JSON.parse(itinerary.content)
              const bannerImage = JSON.parse(content.primaryInfo.bannerImage)
              return (
                <a href={"/" + itinerary.slug.toLowerCase()}
                  className="detail-card"
                >
                  <Row>
                    <Col lg={6} className="thumbContainer" style={{backgroundImage: `url(${settings.IMAGE_URL + bannerImage.thumbUrl +".webp"})`}}>
                    </Col>
                    <Col lg={6} className="content">
                      <h4>{itinerary.title}</h4>
                      <p>{content.primaryInfo.destination_list}</p>
                      <p>
                        {content.primaryInfo.days} Days /
                        {content.primaryInfo.nights} Nights
                      </p>
                      <button className="btn viewbtn">View</button>
                    </Col>
                  </Row>
                </a>
              )
            })}
          </Row>
        {city.articlesForCities.length>0 && (<h4 className="subtitles">Read more on {city.title}</h4>)}

        <Row className="card-container">
          {city.articlesForCities.map(articles => {
            const content = JSON.parse(articles.content)
            console.log(articles)
            const imageCandidate = content.pageContent.find((i)=>{
              return i.type==="IMAGE"
            }).images;
            let imageUrl="";
            if(imageCandidate.length>0){
              imageUrl = imageCandidate[0].imageData.publicUrl
            }
            return (
              <BlogCard
                backgroundImage={settings.IMAGE_URL+imageUrl+".webp"}
                title={content.primaryInfo.title}
                summary={"No Summary"}
                url={`/${articles.slug.toLowerCase()}`}/>
            )
          })}
        </Row>

      </Container>
    </Layout>
  )
}

const BlogCard = ({backgroundImage, title, summary, url})=>{
  return (
    <div className="blogcard">
      <a href={url} alt={title}>
        <div className="image" style={{backgroundImage: `url('${backgroundImage}')`}}>
        </div>
        <h4>{title}</h4>

      </a>

    </div>
  )

}

export const query = graphql`
  query($uri: String!) {
    mysqlCities(slug: { eq: $uri }) {
      google_maps_data
      id
      latitude
      longitude
      meta_description
      meta_keyword
      mysqlId
      overview
      season_months
      slug
      state_id
      title
      states {
        title
        id
        slug
        zones {
          slug
          title
        }
      }
      itineraries {
        id
        title
        slug
        content
      }
      articlesForCities {
        id
        slug
        content
      }
    }
  }
`
